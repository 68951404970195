import { Badge, Box } from '@chakra-ui/react'
import React from 'react'

export const TagList = ({items}) => {
  return (
    <>
      <Box display='flex' mt='2' mb='2' alignItems='baseline'>
          {
            items.map((tag, index) => {
              return (
                <Badge key={index} borderRadius='full' px='4' py='1'>
                  { tag }
                </Badge>)
            })
          }
      </Box>
    </>
    )
}