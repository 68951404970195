import React from 'react'
import Head from 'next/head'

export const HeadTitle = ({ title }) => {
    return (
        <Head>
            <title>{title}</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <link rel="shortcut icon" href="/images/icon.png" />
        </Head>
    );
};
