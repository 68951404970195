import React from 'react'
import { ProjectCard } from './ProjectCard';

export const Projects = ({ projects }) => {
    return (
        <>
            {
                projects.map((project, index) => {
                    return <ProjectCard project={ project } key={ index } />
                })
            }
        </>
    )
}


