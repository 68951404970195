import React from 'react'
import { Button, Flex, Link, Center } from '@chakra-ui/react';
import { FaLinkedinIn, FaTwitter, FaGithub, FaWpforms } from 'react-icons/fa';

export const ButtonSet = () => {
  return (
        <Flex wrap="wrap" padding="0.5rem" justify={'center'} align={'center'}>
            {/* TODO: Create LINKBASE component that will receive link, button name and button text */}
            <Center w='200' m={2}>
                <Link 
                    href='https://www.linkedin.com/in/javier-a-agustin/' 
                    target={'_blank'}>
                    <Button leftIcon={<FaLinkedinIn />}>
                        LinkedIn
                    </Button>
                </Link>
            </Center>

            <Center w='200' m={2}>
                <Link href='https://twitter.com/Javivar022' target={'_blank'}>
                    <Button leftIcon={<FaTwitter />}>
                        Twitter
                    </Button>
                </Link>
            </Center>

            <Center w='200' m={2}>
                <Link href='https://github.com/javier-a-agustin' target={'_blank'}>
                    <Button leftIcon={<FaGithub />}>
                        Github
                    </Button>
                </Link>
            </Center>

            <Center w='200' m={2}>
                <Link href='https://link.storjshare.io/s/jvzz2zrebwg5c56x2l5yhdmzgesa/main/Resume%20-%20Javier%20Fernandez.pdf?download=1' target={'_blank'}>
                    <Button leftIcon={<FaWpforms />}>
                        Resume
                    </Button>
                </Link>
            </Center>

        </Flex >
  )
}
