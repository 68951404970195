import { Box, Button, Container, Heading, Spinner, Text } from '@chakra-ui/react';
import { NavBar } from '../components/common/NavBar';
import { HeadTitle } from '../components/common/HeadTitle';
import { ButtonSet } from '../components/common/ButtonSet';
import { Projects } from '../components/common/Projects';
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useEffect, useState } from 'react';
import { Footer } from '../components/common/Footer';

const Home = ({ projects }) => {

    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setProjectList(projects);
        setLoading(false);
    }, []);

    return (
        <>
            <HeadTitle title="Home" />
            <NavBar />
            <Container>
                <Box borderRadius="lg" mb={6} p={3} textAlign="center">

                    <Heading>Hi there! I am Javier, a Software Developer</Heading>
                    <Text>
                        I am based in Argentina.
                    </Text>
                    <Text>
                        I have knowledge in Python, Django, HTMX (and hyperscript), Postgresql, Javascript, Docker, Serverless.
                    </Text>
                    <Text>
                        Currently i am working full time for an Argentian company since June 2022.
                    </Text>
                    <Text>
                        I have strong interest in Next.js, Flutter, Data Science, and more.
                    </Text>
                    <ButtonSet mt={6} />
                </Box>
                <Box borderRadius="lg" mb={6} p={3} textAlign="center">
                    <Heading>Projects</Heading>
                    {
                        loading && <Spinner />
                    }
                    {

                        !loading && <Projects projects={projectList} />
                    }
                </Box>
            </Container>
            <Footer />
        </>
    );
}



export async function getServerSideProps(context) {
    const SPREADSHEET_ID = process.env.SPREADSHEET_ID;
    console.log("process.env.SPREADSHEET_ID", process.env.SPREADSHEET_ID)
    const SHEET_ID = process.env.SHEET_ID;
    const CLIENT_EMAIL = process.env.GOOGLE_CLIENT_EMAIL;
    const PRIVATE_KEY = process.env.GOOGLE_SERVICE_PRIVATE_KEY;

    const getProjectList = async () => {
        try {
            const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();
            let project_list = [];

            rows.forEach(row => {
                if (row.published === '1') {
                    project_list.push({
                        title: row.title,
                        description: row.description,
                        imageList: row.images.split('|').map(item => item.trim()),
                        technologyList: row.technologies.split(',').map(item => item.trim()),
                        githubUrl: row.github_url,
                        projectUrl: row.project_url
                    })
                }
            });
            return project_list;
        } catch (err) {
            console.log("error", err)
            return [];
        }
    }
    const data = await getProjectList()
    // .then(data => {
    //     return {
    //         props: {
    //             projects: data
    //         },
    //     }
    // })
    // .catch(err => {
    //     console.error(err);
    //     return {
    //         props: {
    //             projects: []
    //         },
    //     }
    // })
    return {
        props: {
            projects: data
        },
    }
}

export default Home;
