import { NavLink } from './NavLink';
import React from 'react';
import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Links = {
    "Home": "/",
    // "Portfolio": "/portfolio",
    "Blog": "https://blog.javivar.xyz/",
    "Contact Me": "/contact-me"
}

export const NavBar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'left'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'left'}>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {Object.entries(Links).map(([key, value]) =>
                                <NavLink value={value} key={key}>{key}</NavLink>
                            )}
                        </HStack>
                    </HStack>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {
                                Object.entries(Links).map(([key, value]) =>
                                    <NavLink value={value} key={key}>{key}</NavLink>
                                )
                            }
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )
};
