import React from 'react'
import {
    Link,
    useColorModeValue,
} from '@chakra-ui/react';

export const NavLink = ({ children, value }) => {
  return (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        target={ value.includes('https://') ? '_blank' : '' }
        href={value}>
            {children}
    </Link>
  )
}
