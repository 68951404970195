
import React from 'react'
import { Box, Button, Link } from '@chakra-ui/react'
import Slide from './Slide';
import { TagList } from './TagList';


export const ProjectCard = ({ project }) => {

  return (

    <Box maxW='auto' borderWidth='1px' borderRadius='md' overflow='hidden' padding={"5px"}>

      <Box mt='2' fontWeight='bold' as='h1' lineHeight='tight'>
        { project.title }
      </Box>

      <Box mt='1' mb='3' as='p' lineHeight='tight' textAlign="justify">
        { project.description }
      </Box>

      {
        project.imageList.length != 0 ? <Slide images={project.imageList} /> : console.log("pass")
      }



      {
        project.githubUrl != null ? <Link href={project.githubUrl} target={'_blank'}>
                                                        <Button mt='2' mb='2'>
                                                            Github Project
                                                        </Button>
                                                      </Link> : console.log('pass')
      }

      {
        project.projectUrl != null ? <Link href={project.projectUrl} target={'_blank'}>
                                                        <Button mt='2' mb='2'>
                                                            Project
                                                        </Button>
                                                      </Link> : console.log('pass')
      }

      {
        project.technologyList.lenght != 0 ? <TagList items={project.technologyList} /> : console.log("pass")
      }
    </Box>
  )
}
